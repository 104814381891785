import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from '../components/layout';
import SEO from '../components/seo';

export const query = graphql`
  query($slug: String!) {
    contentfulNewsPost(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "dddd, MMMM Do, YYYY")
      content {
        json
      }
    }
  }
`;

const NewsTemplate = ({ data: { contentfulNewsPost } }) => {
  const options = {
    renderNode: {
      'embedded-asset-block': node => {
        const {
          data: {
            target: {
              fields: {
                title: { 'en-US': alt },
                file: {
                  'en-US': { url },
                },
              },
            },
          },
        } = node;
        return <img alt={alt} src={url} />;
      },
    },
  };

  return (
    <Layout>
      <SEO title={contentfulNewsPost.title} />
      <h1>{contentfulNewsPost.title}</h1>
      <p>{contentfulNewsPost.publishedDate}</p>
      <p>
        {documentToReactComponents(contentfulNewsPost.content.json, options)}
      </p>
    </Layout>
  );
};

export default NewsTemplate;
